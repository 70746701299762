var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "content-top" },
          [
            _c("p", { staticClass: "title" }, [_vm._v("线索信息")]),
            _vm._l(_vm.customerData, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "text" },
                _vm._l(item.row, function (j, k) {
                  return _c("span", { key: k, staticClass: "text-item" }, [
                    _c("span", [_vm._v(_vm._s(j.title) + "：")]),
                    _c("span", [_vm._v(" " + _vm._s(j.text || "-"))]),
                  ])
                }),
                0
              )
            }),
            _c("div", { staticClass: "btm" }, [
              _c(
                "div",
                {
                  staticClass: "customerbtm bgcolor",
                  on: {
                    click: function ($event) {
                      return _vm.goDetails("0")
                    },
                  },
                },
                [_vm._v("写跟进")]
              ),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "content-bottom" }, [
          _c("div", { staticClass: "box" }),
          _c(
            "div",
            { staticClass: "bottom-title" },
            _vm._l(_vm.serveList, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  class:
                    _vm.statusIcon === item.status
                      ? "active-text"
                      : "title-text",
                  on: {
                    click: function ($event) {
                      return _vm.changeStyle(item.status)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.title) + " ")]
              )
            }),
            0
          ),
          _vm.isShowData
            ? _c("div", [
                _vm.statusIcon == 1
                  ? _c(
                      "div",
                      _vm._l(_vm.recordsVOS, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "bottom-content" },
                          [
                            _c("div", { staticClass: "time" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../../../../assets/images/customerRM/round.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "time-text" }, [
                                _vm._v(_vm._s(item.creationDate)),
                              ]),
                            ]),
                            _c("div", { staticClass: "serve" }, [
                              _c("div", { staticClass: "serve-person" }, [
                                _vm._v("操作人：" + _vm._s(item.createdBy)),
                              ]),
                              _c("div", { staticClass: "box-width" }, [
                                _c("div", { staticClass: "serve-content" }, [
                                  _c("div", { staticClass: "SC-text" }, [
                                    _c("p", { staticClass: "SC-title" }, [
                                      _vm._v("跟进内容:"),
                                    ]),
                                    _c("p", { staticClass: "SC-content" }, [
                                      _vm._v(_vm._s(item.description)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "SC-text" }, [
                                    _c("p", { staticClass: "SC-title" }, [
                                      _vm._v("图片:"),
                                    ]),
                                    item.followResourceVOS.length !== 0
                                      ? _c(
                                          "div",
                                          { staticClass: "SC-img" },
                                          _vm._l(
                                            item.followResourceVOS,
                                            function (i, j) {
                                              return _c(
                                                "div",
                                                {
                                                  key: j,
                                                  staticClass: "imgList",
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "64px",
                                                      height: "64px",
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.urlAddress +
                                                        i.fileId,
                                                      "preview-src-list":
                                                        _vm.getSrcList(
                                                          item.followResourceVOS
                                                        ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c("div", [_vm._v("-")]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm.statusIcon == 2
                  ? _c(
                      "div",
                      _vm._l(_vm.recordsVOS, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "bottom-content" },
                          [
                            _c("div", { staticClass: "time" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../../../../assets/images/customerRM/round.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "time-text" }, [
                                _vm._v(_vm._s(item.createTime)),
                              ]),
                            ]),
                            _c("div", { staticClass: "serve" }, [
                              _c("div", { staticClass: "serve-person" }, [
                                _vm._v("操作人：" + _vm._s(item.createdBy)),
                              ]),
                              _c("div", { staticClass: "box-width" }, [
                                _c("div", { staticClass: "serve-content" }, [
                                  _c("div", { staticClass: "SC-text" }, [
                                    _c("p", { staticClass: "SC-title" }, [
                                      _vm._v("跟进链接:"),
                                    ]),
                                    _c("p", { staticClass: "SC-content" }, [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#4374FA" },
                                          attrs: {
                                            href: _vm.url + item.pdfFile,
                                            download: "",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.url + item.pdfFile))]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _c("div", { staticClass: "present" }, [
                _vm._v("\n                暂无数据\n            "),
              ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "728px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.customerDataDialog.dialogTitle))]),
          ]),
          _vm.dialogVisible
            ? _c("customerViewDialog", {
                attrs: {
                  id: _vm.customerDataDialog.id,
                  dialogStatus: _vm.customerDataDialog.dialogStatus,
                  dialogTitle: _vm.customerDataDialog.dialogTitle,
                },
                on: { close: _vm.handleClose },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }